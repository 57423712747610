<div>
  <!-- QR Code Scanner -->
  <zxing-scanner
    *ngIf="isCameraActive"
    #scanner
    (scanSuccess)="onCodeResult($event)"
    (scanError)="onError($event)"
  >
  </zxing-scanner>
  <button *ngIf="!isCameraActive" mat-button (click)="restartScanner()">Pokreni kameru</button>
</div>

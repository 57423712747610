import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { UserRolePipe } from './pipes/user-role.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ArticleNotesPipe } from './pipes/article-notes.pipe';
import { ArticleStylingDirective } from './directives/article-styling.directive';
import { RouterModule } from '@angular/router';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ArticleItemComponent } from '../procurement/components/outbound-order-init/article-item/article-item.component';
import { NgxsModule } from '@ngxs/store';
import { ArticlesState } from './states/articles/articles.state';
import { CustomersState } from './states/customers/customers.state';
import { InboundOrdersState } from './states/inbound-orders/inbound-orders.state';
import { OrderSortPipe } from './pipes/order-sort.pipe';
import { OutboundOrdersState } from './states/outbound-orders/outbound-orders.state';
import { PartnershipStatusPipe } from './pipes/partnership-status.pipe';
import { CountryCurrencyConverterPipe } from './pipes/country-currency-converter.pipe';
import { SumFilteredPipe } from './pipes/sum-filtered.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

import { MatListModule } from '@angular/material/list';
// import { FirestoreDatePipe } from './pipes/FirestoreDatePipe';
import { ArticleSearchPipe } from './pipes/article-search-pipe.pipe';
import { FirestoreDatePipe } from './pipes/firestoreDatePipe';
import { CustomerSearchPipe } from './pipes/customer-search.pipe';
import { LoadingService } from './services/loading.service';
import { LoadingComponent } from './components/loading-component/loading.component';
import { SharedPricelistComponent } from './components/shared-pricelist/shared-pricelist.component';
import { SharedArticleDetailsComponent } from './components/shared-article-details/shared-article-details.component';
import { NoteOverviewComponent } from './components/note-overview/note-overview.component';
import { GenerateQrComponent } from './components/generate-qr/generate-qr.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScanQrComponent } from './components/scan-qr/scan-qr.component';
import { EmployeeAttendanceStatusPipe } from './pipes/employee-attendance-status.pipe';
import { AbsenceReasonPipe } from './pipes/absence-reason.pipe';
import { RecordingMethodPipe } from './pipes/recording-method.pipe';
import { PrintOptionsComponent } from './components/print-options/print-options.component';


@NgModule({
  declarations: [
    OrderStatusPipe,
    UserRolePipe,
    ArticleNotesPipe,
    OrderSortPipe,
    ArticleStylingDirective,
    // ArticleItemComponent,
    PartnershipStatusPipe,
    FirestoreDatePipe,
    CountryCurrencyConverterPipe,
    SumFilteredPipe,
    ConfirmationDialogComponent,
    LoadingComponent,
    ArticleSearchPipe,
    CustomerSearchPipe,
    SharedPricelistComponent,
    SharedArticleDetailsComponent,
    NoteOverviewComponent,
    GenerateQrComponent,
    ScanQrComponent,
    EmployeeAttendanceStatusPipe,
    AbsenceReasonPipe,
    RecordingMethodPipe,
    PrintOptionsComponent
  ],
  providers: [
    OrderStatusPipe,
    UserRolePipe,
    LoadingService,
    CountryCurrencyConverterPipe
  ],
  imports: [
    NgxsModule.forFeature([
      ArticlesState,
      CustomersState,
      InboundOrdersState,
      OutboundOrdersState
    ]),
    QRCodeModule,
    ZXingScannerModule,
    MatListModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    ScrollingModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBottomSheetModule,
    RouterModule,
    MatStepperModule,
    // InfiniteScrollModule,
    MatSelectModule,
  ],
  exports: [
    OrderStatusPipe,
    UserRolePipe,
    ArticleNotesPipe,
    OrderSortPipe,
    MatListModule,
    PartnershipStatusPipe,
    FirestoreDatePipe,
    CountryCurrencyConverterPipe,
    SumFilteredPipe,
    EmployeeAttendanceStatusPipe,
    AbsenceReasonPipe,
    RecordingMethodPipe,
    QRCodeModule,
    ZXingScannerModule,
    ArticleStylingDirective,
    // ArticleItemComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //FlexLayoutModule,
    MatButtonModule,
    LoadingComponent,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    ScrollingModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBottomSheetModule,
    RouterModule,
    MatStepperModule,
    // InfiniteScrollModule,
    MatSelectModule,
    ArticleSearchPipe,
    CustomerSearchPipe,
    SharedPricelistComponent,
    SharedArticleDetailsComponent,
    NoteOverviewComponent,
    GenerateQrComponent,
    ScanQrComponent,
  ]
})
export class SharedModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recordingMethod'
})
export class RecordingMethodPipe implements PipeTransform {

  transform(value: string): any {
    switch (value) {
      case "SELF_SCAN":
        return "Skeniran"
      case "SELF_REPORT":
        return "Samostalno prijavljen"
      case "ABSENT":
        return "Odustan"
  }
}

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absenceReason'
})
export class AbsenceReasonPipe implements PipeTransform {

  transform(value: string): any {
    switch (value) {
      case "UNJUSTIFIED":
        return "Neopravdano odsustvo"
      case "HOLIDAY":
        return "Godišnji odmor"
      case "PAID_LEAVE":
        return "Plaćeno odsustvo"
      case "MILITARY_EXCERCISE":
        return "Vojna vežba"
      case "INJURY_AT_WORK":
        return "Bolovanje - povreda na radu"
      case "TERMINATED_EMPLOYMENT":
        return "Van radnog odnosa"
      case "SICK_LEAVE":
        return "Bolovanje"
      case "SICK_LEAVE_CHILD_CARE":
        return "Bolovanje - nega deteta"
      case "SICK_LEAVE_SPONSORED":
        return "Bolovanje - fond"
    }
  }

}

import { ArticleCategory } from '../article/articleCategory';
import { Note } from './note';
import { UnitOfMeasurement } from '../util/unitOfMeasurement'
import { ArticleCatalogMetadata } from '../article/articleCatalogMetadata';

export interface OrderArticleWithInit extends OrderArticle {
  initArticle: boolean;
}

export interface IOrderArticle {
  acquisitionPrice: number;
  category: ArticleCategory; //core
  code: string;//core
  externalReferenceCode: string;
  customSalesPrice: number;//orderSpecific
  discountPercent: number;
  id: string; //core;
  maxDiscount: number;
  name: string;// core
  note: string;//orderSpecific
  notes: Array<Note>;
  offeredDeliveryDate: Date;//orderSpecific
  offeredQuantity: number;//orderSpecific
  orderedQuantity: number;//orderSpecific
  productMaxOrderQuantity: number; //UI, should become core/catalog specific
  productMinOrderQuantity: number; //UI, should become core/catalog specific
  productTransportPackageSize: number; //UI, chould become core/catalog specific
  realizedSalesPrice: number;//orderSpecific
  receivedQuantity: number;//orderSpecific
  sentQuantity: number;//orderSpecific
  standardSalesPrice: number;//orderSpecific
  stepDown: number; //UI, should become core/catalog specific
  stepUp: number; //UI, should become core/catalog specific
  unitOfMeasurement: UnitOfMeasurement;// core
  VAT: number;
  packageQuantity: number;
  imageUrl: string;
}

export class OrderArticle {
  _sourcedata: ArticleCatalogMetadata;
  acquisitionPrice: number;
  category: ArticleCategory; //core
  code: string;//core
  externalReferenceCode: string;
  customSalesPrice: number;//orderSpecific
  discountPercent: number;
  id: string; //core
  maxDiscount: number;
  name: string;// core
  note: string;//orderSpecific
  notes: Array<Note>
  offeredDeliveryDate: Date;//orderSpecific
  offeredQuantity: number;//orderSpecific
  orderedQuantity: number;//orderSpecific
  productMaxOrderQuantity: number; //UI, should become core/catalog specific
  productMinOrderQuantity: number; //UI, should become core/catalog specific
  productTransportPackageSize: number; //UI, chould become core/catalog specific
  realizedSalesPrice: number;//orderSpecific
  receivedQuantity: number;//orderSpecific
  sentQuantity: number;//orderSpecific
  standardSalesPrice: number;//orderSpecific
  stepDown: number; //UI, should become core/catalog specific
  stepUp: number; //UI, should become core/catalog specific
  unitOfMeasurement: UnitOfMeasurement;// core
  VAT: number;
  packageQuantity: number;
  imageUrl: string;
  //imageUrls: [string];
  imageUrls: string;
  lastOrderedAt?: any | null;

  constructor(obj: any) {
    this.acquisitionPrice = obj && obj.acquisitionPrice || 0;
    this.category = obj && new ArticleCategory(obj.category) || new ArticleCategory();
    this.code = obj && obj.code || "";
    this.externalReferenceCode = obj && obj.externalReferenceCode || (obj.code ? obj.code : "");
    this.customSalesPrice = obj && +obj.customSalesPrice || null;
    this.discountPercent = obj && +obj.discountPercent || null;
    if (this.discountPercent == null) {
      this.discountPercent = 0
    }
    this.maxDiscount = obj && obj.maxDiscount || null;
    if (this.maxDiscount == null) {
      this.maxDiscount = 0
    }
    this.notes = obj && obj.notes && obj.notes.map((x: any) => new Note(x)) || [];
    this.id = obj && obj.id || "";
    this.name = obj && obj.name || "";
    this.note = obj && obj.note || "";
    this.offeredDeliveryDate = obj && obj.offeredDeliveryDate || null;
    this.offeredQuantity = obj && obj.offeredQuantity || null;
    if (obj && obj.offeredQuantity === 0) {
      this.offeredQuantity = 0
    }
    this.orderedQuantity = obj && obj.orderedQuantity || null;
    // this.orderedQuantity = obj && obj.orderedQuantity || 0;
    this.productMaxOrderQuantity = obj && obj.productMaxOrderQuantity || 9999999;
    this.productMinOrderQuantity = obj && obj.productMinOrderQuantity || 0;
    this.productTransportPackageSize = obj && obj.productTransportPackageSize || 1;
    this.realizedSalesPrice = obj && obj.realizedSalesPrice && obj.realizedSalesPrice || null;
    this.receivedQuantity = obj && obj.receivedQuantity || null;
    this.sentQuantity = obj && obj.sentQuantity || null;
    this.standardSalesPrice = obj && obj.standardSalesPrice && obj.standardSalesPrice || null;
    this.stepDown = obj && obj.stepDown || 1;
    this.stepUp = obj && obj.stepUp || 5;
    this.unitOfMeasurement = obj && obj.unitOfMeasurement || null;
    this.VAT = obj && obj.VAT || 0;

    this.packageQuantity = obj && obj.packageQuantity || 0;
    this.imageUrl = obj && obj.imageUrl || null;
    this.imageUrls = obj && obj.imageUrls || null;
    this._sourcedata = obj && obj._sourcedata || null;

    this.lastOrderedAt = obj && obj.lastOrderedAt && (typeof obj.lastOrderedAt.toDate == 'function' && obj.lastOrderedAt.toDate() || obj.lastOrderedAt) || null; //new Date();
  }

  toFirestore(): any {
    return {
      _sourcedata: this._sourcedata,
      acquisitionPrice: this.acquisitionPrice,
      id: this.id,
      category: this.category.toFirestore(),
      code: this.code,
      externalReferenceCode: this.externalReferenceCode,
      customSalesPrice: +this.customSalesPrice,
      discountPercent: +this.discountPercent,
      maxDiscount: this.maxDiscount,
      name: this.name,
      orderedQuantity: this.orderedQuantity,
      offeredQuantity: this.offeredQuantity,
      sentQuantity: this.sentQuantity,
      receivedQuantity: this.receivedQuantity,
      offeredDeliveryDate: this.offeredDeliveryDate,
      standardSalesPrice: this.standardSalesPrice,
      realizedSalesPrice: this.realizedSalesPrice,
      note: this.note,
      notes: this.notes.map((note: Note) => note.toFirestore()),
      unitOfMeasurement: this.unitOfMeasurement,
      VAT: this.VAT,
      packageQuantity: this.packageQuantity,
      imageUrls: this.imageUrls,
      // lastOrderedAt: this.lastOrderedAt
    }
  }

  fromFirestore(snapshot: any, options: any): any {
    const data = snapshot.data(options);
    return new OrderArticle(data);
  }
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-print-options',
  templateUrl: './print-options.component.html',
  styleUrls: ['./print-options.component.sass']
})
export class PrintOptionsComponent {
  constructor(public dialogRef: MatDialogRef<PrintOptionsComponent>) {}

  selectAll = false;

  onSwitchChange(event: any): void {
    console.log('Switch changed:', this.selectAll);
  }

  selectOption(option: string): void {
    this.dialogRef.close({option, switchValue: this.selectAll});
  }
}

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { BehaviorSubject } from 'rxjs';
import { SpinnerComponent } from '../components/spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private spinnerRef: OverlayRef = this.cdkSpinnerCreate();

  loaderValue$ = new BehaviorSubject<number>(0);
  loaderValue = this.loaderValue$.asObservable();


  constructor(
    private overLay: Overlay
  ) { }

  private cdkSpinnerCreate() {
    return this.overLay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overLay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    })
  }

  showSpinner() {
    const spinnerPortal = new ComponentPortal(MatProgressSpinner);
    const spinnerInstance = this.spinnerRef.attach(spinnerPortal).instance;
    spinnerInstance.diameter = 50; // Adjust the diameter as needed
    spinnerInstance.color = 'accent'; // Define color (e.g., primary, accent, warn)
    spinnerInstance.mode = 'indeterminate'; // Use indeterminate for spinning animation

//    this.spinnerRef.attach(new ComponentPortal(MatProgressSpinner))
  }

  stopSpinner() {
    if (this.spinnerRef.hasAttached()) this.spinnerRef.detach();
  }

  showLoader() {
    this.spinnerRef.attach(new ComponentPortal(SpinnerComponent))
  }

  updateLoader(value: number) {
    this.loaderValue$.next(value)
  }

  stopLoader() {
    this.loaderValue$.next(0);
    if (this.spinnerRef.hasAttached()) this.spinnerRef.detach();
  }
}

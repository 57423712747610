import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeeAttendanceStatus'
})
export class EmployeeAttendanceStatusPipe implements PipeTransform {

  transform(value: string): any {
    switch (value) {
      case "PRESENT":
        return "Prisutan"
      case "CHECK_IN_REQUESTED":
        return "Prijavljen"
      case "ABSENT":
        return "Odsutan"
    }
  }

}

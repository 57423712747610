import { WarehouseStockLevel } from "./warehouseStockLevel";
import { ArticleCategory, IArticleCategory } from "./articleCategory";
import { ArticleIntegrationData, IArticleIntegrationData } from "./articleIntegrationData";

export interface IArticle {

  _integration: IArticleIntegrationData;
  acquisitionPrice: number;
  category: IArticleCategory;
  code: string;
  id: string;
  imageUrl: string;
  isInactive: boolean;
  isReadOnly: boolean;
  maxDiscount?: number;
  name: string;
  standardSalesPrice: number;
  packageQuantity: number;
  transportPackageQuantity: number;
  unitOfMeasurement: string;
  VAT: number;
  EAN13: string;
  warehouses?: Array<WarehouseStockLevel>;
}

export class Article implements IArticle {

  _integration: ArticleIntegrationData;
  acquisitionPrice: number;
  category: ArticleCategory;
  code: string;
  externalReferenceCode: string;
  id: string;
  imageUrl: string;
  isInactive: boolean;
  isReadOnly: boolean;
  maxDiscount?: number;
  name: string;
  standardSalesPrice: number;
  packageQuantity: number;
  transportPackageQuantity: number
  unitOfMeasurement: string;
  VAT: number;
  EAN13: string;
  warehouses?: Array<WarehouseStockLevel>;
  lastOrderedAt?: Date | null;


  constructor(obj?: any) {
    this._integration = obj && obj._integration && new ArticleIntegrationData(obj._integration) || new ArticleIntegrationData();
    this.acquisitionPrice = obj && obj.acquisitionPrice || 0;
    this.category = obj && new ArticleCategory(obj.category) && new ArticleCategory(obj.category) || new ArticleCategory();
    this.id = obj && obj.id || "";
    this.code = obj && obj.code || "";
    this.externalReferenceCode = obj && obj.externalReferenceCode || (obj.code ? obj.code : "");

    this.imageUrl = (obj.imageUrls && obj.imageUrls[0]) || "";
    // this.imageUrl = obj && obj.imageUrl || "";

    this.isInactive = obj && obj.isInactive || false;
    this.isReadOnly = obj && obj.isReadOnly || false;
    this.maxDiscount = obj && obj.maxDiscount || 0;
    if (obj && obj.maxDiscount && obj.maxDiscount === null) {
      this.maxDiscount = 0
    }
    this.name = obj && obj.name || "";
    this.standardSalesPrice = obj && obj.standardSalesPrice && Math.round(obj.standardSalesPrice * 10000) / 10000 || 0;
    this.packageQuantity = obj && obj.packageQuantity || 0;
    this.transportPackageQuantity = obj && obj.transportPackageQuantity || 0;
    this.unitOfMeasurement = obj && obj.unitOfMeasurement || "";
    this.VAT = obj && obj.VAT || 0;
    if (obj && obj.VAT && obj.VAT === 0) {
      this.VAT = 0
    }
    this.EAN13 = obj && obj.EAN13 || "";
    this.warehouses = obj && obj.warehouses && obj.warehouses.length && obj.warehouses.map((level: any) => new WarehouseStockLevel(level))
    this.lastOrderedAt = obj && obj.lastOrderedAt && (typeof obj.lastOrderedAt.toDate == 'function' && obj.lastOrderedAt.toDate() || obj.lastOrderedAt) || null; //new Date();
  }

  toFirestore() {
    return {
      _integration: this._integration.toFirestore(),
      acquisitionPrice: this.acquisitionPrice,
      category: this.category.toFirestore(),
      code: this.code,
      packageQuantity: this.packageQuantity,
      transportPackageQuantity: this.transportPackageQuantity,
      id: this.id,
      isInactive: this.isInactive,
      isReadOnly: this.isReadOnly,
      maxDiscount: this.maxDiscount == undefined ? null : this.maxDiscount,
      name: this.name,
      standardSalesPrice: this.standardSalesPrice,
      unitOfMeasurement: this.unitOfMeasurement,
      EAN13: this.EAN13,
      warehouses: this.warehouses ? this.warehouses.map((level: WarehouseStockLevel) => level.toFirestore()) : [],
    }
  }
}

export class PopupArticle extends Article {

  checked: boolean;

  constructor(article: Article, isChecked: boolean = false) {
    super(article)
    this.checked = isChecked
  }


}
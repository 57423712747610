import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/auth/services/user.service';
import { OrgUser } from '../../models/user/user';
import { NavigationService } from '../../services/navigation.service';
import { Store } from '@ngxs/store';
import { OrganizationConfigService } from '../../services/organization-config.service';
import { OrganizationConfig, OrganizationConfigs } from '../../models/organization/config';
import { OrganizationService } from '../../services/organization.service';
import { Organization } from '../../models/organization/organization';
import { UserOrganizationsState } from 'src/app/profile/states/user-organizations/user-organizations.state';
import { UserOrganizationsActions } from 'src/app/profile/states/user-organizations/user-organizations.actions';
import { GlobalActions } from '../../actions/global.actions';
import { MessagingService } from '../../services/messaging.service';
import posthog from 'posthog-js';


type _moduleName = 'warehouseOrders' | 'salesrep' | 'procurement' | 'expenses' | 'settings' | 'timesheets'
type _route = { path: string, roles: string[], name: string, moduleName: _moduleName }

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass']
})
export class NavigationComponent implements OnInit {

  title: Observable<string>;
  orgUser: OrgUser = new OrgUser();

  activeModules: OrganizationConfigs.ActiveModules = new OrganizationConfigs.ActiveModules()
  organization: Organization = new Organization();
  userOrganizations: Organization[] = [];
  selectedOrg: Organization = new Organization();

  showBackNavigation: boolean = true;

  routes: _route[] = [
    {
      path: 'warehouse-orders',
      roles: ['ADMIN', 'WAREHOUSEMANAGER', 'ACCOUNTANT', 'WAREHOUSECLERK'],
      name: 'Distribucija',
      moduleName: 'warehouseOrders'
    },
    {
      path: 'salesrep',
      roles: ['ADMIN', 'SALESREP'],
      name: 'Komercijala',
      moduleName: 'salesrep'
    },
    {
      path: 'procurement',
      roles: ['ADMIN', 'PROCUREMENTMANAGER'],
      name: 'Nabavka',
      moduleName: 'procurement'
    },
    {
      path: 'expenses',
      roles: ['ADMIN', 'PROCUREMENTMANAGER', 'SALESREP', 'WAREHOUSEMANAGER'],
      name: 'Troškovi',
      moduleName: 'expenses'
    },
    {
      path: 'users-management',
      roles: ['ADMIN'],
      name: 'Podešavanja',
      moduleName: 'settings'
    },
    {
      path: 'timesheets',
      roles: [], //edit this thoughtfully
      name: 'Evidencija prisustva',
      moduleName: 'timesheets'

    }/* ,
    {
      path: 'profile',
      roles: ['ADMIN', 'SALESREP', 'WAREHOUSEMANAGER', 'ACCOUNTANT'],
      name: 'Profil'
    } */
  ]


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private messagingService: MessagingService,
    private navigationService: NavigationService,
    private router: Router,
    private userService: UserService,
    private store: Store,
    private organizationConfigService: OrganizationConfigService,
    private orgService: OrganizationService,
  ) {
    this.navigationService.startSaveHistory()
    this.title = this.navigationService.getTitle();

    // Get the current URL including query parameters if any
    var _currentUrl = this.router.url;

    // If you want to react to URL changes you can subscribe to the events as well
    this.router.events.subscribe((val) => {
      // If you want just the path without parameters
      _currentUrl = this.router.routerState.snapshot.url;

      if (_currentUrl.includes('customer-orders/new-order')) {
        this.showBackNavigation = false
      } else {
        this.showBackNavigation = true
      }

      // console.log("currentUrl2:", this.currentUrl)
      // Or if you need the full URL with parameters
      // this.currentUrl = this.router.createUrlTree([], { relativeTo: this.activatedRoute }).toString();
      // console.log("currentUrl3:", this.currentUrl)

    });

  }

  ngOnInit(): void {

    const _curUser = this.userService.getCurrentUser();
    console.log("posthog:_curUser:", _curUser)

    posthog.init('phc_M7C3vZW0wAUgvWRimPEpSCRCzwluRGFr7NZ0e2O6QAB', {
      api_host: 'https://eu.i.posthog.com',
      loaded: function (posthog) {
        // posthog.identify(
        //   'distinct_id', // Required. Replace 'distinct_id' with your user's unique identifier
        //   {
        //     id: _curUser?.id,
        //     name: _curUser?.fullName,
        //     orgId: _curUser?.orgId
        //   },
        // );
        if (_curUser && _curUser.id) {
          posthog.identify(
            _curUser.id,  // Use the unique ID of the current user
            {
              id: _curUser.id,
              name: _curUser.fullName,
              orgId: _curUser.orgId
            }
          );
        } else {
          console.error('User information is not available for identification.');
        }

      }

    })

    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();

    this.userService.orgUserAsObservable().subscribe((orgUser: OrgUser) => {
      this.orgUser = orgUser;
    })
    this.orgService.orgAsObservable().subscribe((org: Organization) => {
      this.organization = org;
    })
    this.organizationConfigService.configAsObservable().subscribe((config: OrganizationConfig) => {
      this.activeModules = config.activeModules || this.activeModules
    })
    this.store.dispatch(UserOrganizationsActions.GetOrganizations)

    this.store.select(UserOrganizationsState.getOrganizations).subscribe((orgs: Organization[]) => {
      this.userOrganizations = orgs;
      this.getSelectedOrg();
    })

  }

  signOut() {
    this.authService.clearAllStatesAndSignout()
  }

  isVisibleToUser(roles: string[], path: string) {
    if (path === 'timesheets') {
      return true; // Allow visibility for any role for the 'timesheets' path
    }
    if (!this.orgUser || !this.orgUser.roles.length) {
      return false
    } else {
      return this.orgUser.roles.find((role: string) => roles.find((r: string) => r === role));
    }
  }

  isVisibleToOrg(moduleName: _moduleName) {
    return this.activeModules[moduleName]
  }

  navigateBack() {
    this.navigationService.goBack();
  }

  compareOrgs(org1: any, org2: any): boolean {
    return org1.id == org2.id
  }

  getSelectedOrg() {
    const orgId = this.orgService.getCurrentOrganizationId()
    this.selectedOrg = this.userOrganizations.find((org: Organization) => org.id == orgId) || new Organization();
  }

  async changeOrg() {
    this.store.dispatch(GlobalActions.ClearAll)
    this.orgService.switchOrganization(this.selectedOrg.id).then(() => {
      window.location.reload()
    })
  }

}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EventSignatureService } from '../../services/event-signature.service';
import { Location } from '@angular/common';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'app-scan-qr',
  templateUrl: './scan-qr.component.html',
  styleUrls: ['./scan-qr.component.sass']
})
export class ScanQrComponent {

  @Output()
  readQrCode: EventEmitter<any> = new EventEmitter()

  @ViewChild('scanner', { static: false }) scanner!: ZXingScannerComponent

  constructor(
    private location: Location,
  ) { }

  error: string | null = null;
  isCameraActive: boolean = false; // Controls camera state

  onCodeResult(result: string): void {
    this.isCameraActive = false; // Turn off the camera
    this.readQrCode.emit(result)   
  }

  onError(error: any): void {
    console.error('Error scanning QR Code:', error);
    this.error = 'An error occurred while scanning the QR code.';
  }

  restartScanner(): void {
    this.isCameraActive = true
  }

  goBack(): void {
    // Logic for navigating back, e.g., using Angular Router
    this.location.back()
    this.isCameraActive = false
    console.log('Back button clicked');

  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { UserService } from 'src/app/auth/services/user.service';
import { OrgUser } from 'src/app/core/models/user/user';
import { v4 as uuidv4 } from 'uuid';

export interface EventSignature {
  id: string,
  signedDocumentId: string,
  signedDocumentType: string,
  createdByUserId: string,
  createdByUserName: string,
  qrCodeData: string,
  status: string,
  createdAt: Date
}

export interface QrIssuer {
  crossOrg: boolean,
  realtime: boolean,
  hookUuid: string
}

export interface QrOrderHandshakeHook {
    orderId: string,
    supplierId: string,
    customerId: string,
    createdAt:Date
}

export interface QrTimesheetsHandshakeHook {
  hookUuid: string,
  orgId: string,
  userId: string,
  createdAt: Date,
  location: string
}

export interface QrCodeData {
  hookUuid: string,
}

@Injectable({
  providedIn: 'root'
})
export class EventSignatureService {

  qrData: QrCodeData = {
    hookUuid: '',
  }

  hookUuid: string = ''
  event: string = ''

  constructor(
    private db: AngularFirestore,
    private router: Router,
  ) { }

  createTimesheetHandshakeQrCode(orgUser: OrgUser){
    let hookUuid = uuidv4()
    this.qrData = {
      hookUuid: hookUuid,
    }
    let hookData: QrTimesheetsHandshakeHook = {
      hookUuid: hookUuid,
      orgId: orgUser.orgId,
      userId: orgUser.id,
      createdAt: new Date(),
      location: ""
    }
    
    //this.db.collection('organizations').doc(orgUser.orgId).collection('_timesheets_hooks').doc(hookUuid).set(hookData)
  }

  createOrderHandshakeQrCode(crossOrg: boolean, realtime: boolean, orgUser: OrgUser, data: QrOrderHandshakeHook): QrIssuer{
    let hookUuid = uuidv4()
    this.qrData =  {
      hookUuid: hookUuid,
    }
    if(crossOrg){
      this.db.collection('_signature_hooks').doc(hookUuid).set(data)
    } else {
      this.db.collection('organizations').doc(orgUser.orgId).collection('_signature_hooks').doc(hookUuid).set(data)
    }
    this.router.navigate(['app/warehouse-orders/orders/generate-qr'])
    return {crossOrg, realtime, hookUuid } 
  }

  readQrCode(){
    this.router.navigate(['app/warehouse-orders/orders/scan-qr'])
  }

  getHookUuid(){
    console.log(this.qrData)
    return this.qrData 
  }

  checkIn(){
    this.event = 'check-in'
    this.router.navigate(['app/timesheets/record/check-in'])
  }

  checkOut(){
    this.router.navigate(['app/timesheets/record/check-out'])
  }



  recordTimesheet(result: any, event: string){
    console.log(result, event)
    // doc data = userId, createdAt, location, eventType
    //record to database - what path? /organizations/this.userService.getOrgUser().orgId/timesheets/doc
  }
}

// Mina , molim te samo da svi kablovi idu uz ivice zidova jer kao sto smo rekli, za ovako visoko luksuznu zgradu neprihvatljivo je da plafon ide ispod 260.
import { Component, Input, OnInit } from '@angular/core';
import { EventSignatureService } from '../../services/event-signature.service';
import { DocumentMetadata } from 'src/app/core/models/documentMetadata';
import { InboundOrder } from 'src/app/core/models/order/inboundOrder';
import { OutboundOrder } from 'src/app/core/models/order/outboundOrder';
import { Location } from '@angular/common';

@Component({
  selector: 'app-generate-qr',
  templateUrl: './generate-qr.component.html',
  styleUrls: ['./generate-qr.component.sass']
})
export class GenerateQrComponent implements OnInit {

  @Input()
  qrData: any = "error"

  qrCodeUrl: string | null = null

  constructor(
    private location: Location,
    private eventSignatureService: EventSignatureService
  ){}

  ngOnInit(): void {
    console.log(this.qrData)
    // this.qrData = this.eventSignatureService.getHookUuid()
    // console.log(this.qrData)
    // if(this.qrData){
    //   this.qrData = JSON.stringify(this.qrData)
    // } else {
    //   console.log("No QR data provided")
    // }
  }
  

  // orderSignature:  {
  //   issuer: {
  //     docRef: string,
  //     docSnapshot: InboundOrder
  //   },
  //   signatory: {
  //     docRef: string,
  //     docSnapshot: OutboundOrder
  //   },
  //   _metadata: DocumentMetadata
  // } = {
  //   issuer: {
  //     docRef: '',
  //     docSnapshot: new InboundOrder()
  //   },
  //   signatory: {
  //     docRef: '',
  //     docSnapshot: new OutboundOrder()
  //   },
  //   _metadata: new DocumentMetadata()
  // }

  // goBack(): void {
  //   this.location.back()
  // }

}
